
import React, { useState } from 'react';

const Frequently = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to handle the click on the cross icon
  const handleClose = () => {
    setActiveIndex(null); // Close the active item
  };

  // Function to handle the click on the question
  const handleClick = (index) => {
    // Only open the item if it is not already active
    if (activeIndex !== index) {
      setActiveIndex(index); // Open the selected item
    }
  };

  const questions = [
    {
      title: 'مين "هالو؟"',
      content: '"هالو" منصة للتدريس الخصوصي، تستهدف الطلاب من التمهيدي (KG) إلى الصف الثالث الثانوي، وكمان نعطي دروس خصوصية لبعض التخصصات الجامعية',
    },
    {
      title: 'كيف تختارون المعلمين في "هالو؟"',
      content: 'نهتم إن المعلمين عندنا يكونون بأعلى كفاءة،  كل معلم يمر بتقييم من مشرفين متمييزين بالمادة العلمية الخاصة بهم وبكذا نضمن إنك توصل لنتائج مميزة مع معلم محترف',
    },
    {
      title: 'كيف أقدر أشترك في "هالو" وايش الباقات المتوفّرة حاليا؟',
      content: '.تقدر تتواصل معانا عشان نختار الباقة اللي تناسبك، عندنا باقات تبدأ من 8 ساعات وتوصل إلى 16 ساعة والأجمل إنك تقدر تستفيد من الباقة في أكثر من مادة',
    },
    {
      title: 'متى تتواصلون معي بعد ما أقدم الطلب؟',
      content: '.فريقنا بيتواصل معك خلال 24 إلى 48 ساعة من تقديم الطلب',
    },
    {
      title: 'لو ما ناسبني المعلم بعد حصة أو كم حصة، هل أقدر أغيّر المعلم؟',
      content: '.تقدر تغير المعلم في أي وقت وتستفيد من باقي الساعات مع معلم ثاني من فريقنا',
    },
    {
      title: 'كم أقل عدد حصص أقدر أحجزها؟',
      content: 'ما في حد أدنى، تقدر تبدأ بحصة وحدة فقط.',
    },
    {
      title: 'لازم أستخدم الباقة خلال فترة محددة؟',
      content: 'ايوا تقدر تستخدم الباقة على راحتك خلال 3 شهور كاملة',
    },
  ];

  return (
    <div className="lg:w-[53%] w-[90%] rounded-lg mx-auto flex flex-col lg:mt-20 mt-12 mb-7">
      <h1 className="self-center lg:text-3xl text-2xl font-bold mb-6">الأسئلة الشائعة</h1>
      <h4 className="self-center mt-[-10px] mb-8 lg:text-base text-xs font-medium">
        فيما يلي الأسئلة الأكثر شيوعًا التي يطرحها الطلاب حول خدماتنا
      </h4>

      {questions.map((item, index) => (
        <div
          key={index}
          className={`w-full rounded-xl border ${activeIndex === index ? 'border-none' : 'border-[#AF87F4]'} shadow-sm p-4 mb-2 cursor-pointer
            ${activeIndex === index ? 'bg-[#f1efff] shadow border-[#AF87F4]' : 'hover:bg-[#f1efff] hover:shadow hover:border-[#AF87F4]'}`}
          onClick={() => handleClick(index)}
        >
          <header className="flex justify-between items-center font-bold ">
            {activeIndex === index ? (
              <img 
                src="cross.png" 
                alt="Cross Icon" 
                className="" 
                onClick={handleClose} // Close the active item when cross is clicked
              />
            ) : (
              <img src="icon.png" alt="Plus Icon" className="" /> // Plus icon
            )}
            <h3 className='text-right'>{item.title}</h3>
          </header>
          {activeIndex === index && (
            <p className="text-right mt-2 text-base"style={{ direction: 'rtl', unicodeBidi: 'isolate' }}>
              {item.content}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Frequently;