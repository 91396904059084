
import React, { useState } from 'react';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('home'); // Added state for active section

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const openLink = () => {
        window.open('https://form.typeform.com/to/uLyKu9PS', '_blank');
    };
    const openTeacherLink = () => {
        window.open('https://form.typeform.com/to/Dg23LecV', '_blank');
      };

    const scrollToSection = (sectionId) => {
        setActiveSection(sectionId); // Set the active section on click
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <nav className="w-full text-[14px] sticky top-0 z-10 font-inter">
                <div className="flex items-center justify-between w-full py-2 px-4 md:px-8 relative z-[9999]">
                    {/* Left Section: Buttons */}
                    <div className="hidden lg:flex space-x-4">
                        {[
                            { name: "الأسئلة الشائعة", id: "faq" },
                            { name: "الباقات", id: "packages" },
                            { name: "لماذا نحن", id: "platform" },
                            { name: "الرئيسية", id: "home" },
                        ].map((item) => (
                            <button
                                key={item.id}
                                className={`relative py-2 px-4 hover:font-bold inline-block ${activeSection === item.id ? 'after:content-[""] after:absolute after:left-1/2 after:bottom-0 after:translate-x-[-50%] after:w-1/2 after:h-[6px] after:bg-[#5C4FD5] after:rounded-full' : ''
                                    }`}
                                onClick={() => scrollToSection(item.id)}
                            >
                                {item.name}
                            </button>


                        ))}
                    </div>

                    {/* Logo Section */}
                    <div className="w-24 lg:w-auto lg:mb-[-33px] mb-[-13px] ml-5 lg:ml-[-65px]">
                        <img src="Group212.svg" alt="Logo" className="w-[73px] h-[28px] md:w-[113px] md:h-[49px]" />
                    </div>

                    {/* Right Section: Action Buttons */}
                    <div className="flex items-center lg:space-x-9 space-x-3">
                        <button className="hidden lg:block border-b-2 border-[#5C4FD5]"onClick={openTeacherLink}>انضم كمدرس مع هالو</button>
                        <button
                            className="bg-[#5C4FD5] text-white py-2 px-7 rounded-full shadow-lg shadow-[#AF87F4]/50"
                            onClick={openLink}
                        >
                            احجز حصتك التجريبية
                        </button>
                        <img
                            src="Menu.png"
                            alt="Menu"
                            className="lg:hidden cursor-pointer"
                            onClick={toggleMenu}
                        />
                    </div>
                </div>

                {/* Decorative Images */}
                <div className="hidden lg:block">
                    <img src="Vector 1.png" alt="Vector decoration" className="w-full lg:mt-[-65px] h-[125px] ml-[-1px]" />
                </div>
                <div className="lg:hidden">
                    <img src="Vector 3.png" alt="Vector decoration" className="w-full mt-[-52px]" />
                </div>
            </nav>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="lg:hidden flex flex-col space-y-2 fixed right-0 top-[50px] bg-white shadow-lg rounded-tl-xl rounded-bl-xl p-5 w-[74%] z-10">
                    {[
                        { name: "الرئيسية", id: "home" },
                        { name: "لماذا نحن", id: "platform" },
                        { name: "الباقات", id: "packages" },
                        { name: "الأسئلة الشائعة", id: "faq" },
                    ].map((item) => (
                        <button
            key={item.id}
            className={`text-right relative py-4 px-4 hover:font-bold  ${
                activeSection === item.id ? 'after:content-[""] after:absolute after:left-[85%] after:bottom-0 after:translate-x-[-50%] after:w-[20%] after:h-[6px] after:bg-[#5C4FD5] after:rounded-full' : ''
            }`}
            onClick={() => scrollToSection(item.id)}
        >
            {item.name}
        </button>
                    ))}
                    <div className='p-[2px] rounded-full bg-gradient-to-b from-[#E7D9FF] to-[#AF87F4] !mt-5 !mb-5'>

                    <button
                        className="w-full h-full bg-white py-2 rounded-full "
                        onClick={openTeacherLink}
                        >
                        انضم كمدرس مع هالو
                    </button>
                        </div>
                </div>
            )}
        </>
    );
};

export default Navbar;