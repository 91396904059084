
// import React from 'react';

// const Platform = () => {
//   // مختلف امیجز کی ایک ایری
//   const images = [
//     {
//       src: "folder 8.png",  // پہلی تصویر
//       text: "نعتمد باختيارنا على معلمين بخبرات جيدة ويتم تقييمهم من قبل مشرفين متخصصين",
//       title: "معلمين بكفاءات عالية"
//     },
//     {
//       src: "Magnify glass2 2.png",  // دوسری تصویر
//       text: "من خلال مشرفين متخصصين في كل مادة",
//       title: "متابعة مستمرة 2"
//     },
//     {
//       src: "online-study.png",  // تیسری تصویر
//       text: "يمكنك اختيار باقات مناسبة لاحتياجات طفلك",
//       title: "باقات مرئية"
//     },
//     {
//       src: "school.png",  // چوتھی تصویر
//       text: "دروس خصوصية بالوقت المناسب لك والمعلم المناسب لطفلك",
//       title: "دروس فردية مباشر (أونلاين)"
//     }
//   ];

//   return (
//     <div className='w-full h-auto mb-[-120px] flex flex-col items-center justify-center relative lg:mt-[-120px]'>
//       <img
//         src="Rectangle 43591.png"
//         alt="Logo"
//         className="mb-8 relative lg:h-[29rem] h-[37rem] lg:h-none w-[100%]" // تصویر کے نیچے کچھ مارجن
//       /> 
//       <div className="flex flex-col items-center justify-center mx-auto absolute mb-[4rem] lg:mb-8">
//         <h1 className="text-center lg:text-3xl text-xl font-bold mb-9 flex items-center justify-center">
//           هو الاختيار المناسب
//           <img
//             src="Frame 1171275990.png"
//             alt="Platform logo"
//             className="mx-2 border-[1px] border-[#AF87F4] h-11 object-contain rounded-3xl w-20 px-2" // امیج کی چوڑائی اور اونچائی کم کر دی گئی
//           /> {/* لوگو درمیان میں شامل */} 
//           اختيارك لمنصة
//         </h1>

//         <div className="grid lg:grid-cols-2 gap-4">
//           {images.map((image, index) => (
            
//             <div key={index} className="flex flex-row-reverse items-center lg:w-[510px] w-[390px] h-auto rounded-2xl border border-[#AF87F4] bg-white" >
//               {/* دائیں جانب چھوٹی تصویر */}
//               <img
//                 src={image.src}
//                 alt={`Relevant icon ${index + 1}`} // وضاحتی `alt` attribute
//                 className="w-28 h-24 pr-2" // تصویر کا سائز چھوٹا کر دیا گیا
//               />
//               {/* بائیں جانب ٹیکسٹ */}
//               <div className="flex flex-col">
//                 <h2 className={`lg:text-lg text-sm font-extrabold lg:font-bold text-end ${image.title === "دروس فردية مباشر (أونلاين)" ? 'font-bold' : ''}`}>
//                   {image.title}
//                 </h2> {/* ہر تصویر کے لیے علیحدہ عنوان */}
//                 {/* ہر تصویر کے لیے علیحدہ <p> */}
//                 <p className='text-end pl-5 text-sm lg:text-base '>{image.text}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Platform;


import React from 'react';

const Platform = () => {
  // مختلف امیجز کی ایک ایری
  const images = [
    {
      src: "folder 8.png",  // پہلی تصویر
      text: "نعتمد باختيارنا على معلمين بخبرات جيدة ويتم تقييمهم من قبل مشرفين متخصصين",
      title: "معلمين بكفاءات عالية"
    },
    {
      src: "Magnify glass2 2.png",  // دوسری تصویر
      text: "من خلال مشرفين متخصصين في كل مادة",
      title: "متابعة مستمرة "
    },
    {
      src: "online-study.png",  // تیسری تصویر
      text: "يمكنك اختيار باقات مناسبة لاحتياجات طفلك",
      title: "باقات مرئية"
    },
    {
      src: "school.png",  // چوتھی تصویر
      text: "دروس خصوصية بالوقت المناسب لك والمعلم المناسب لطفلك",
      title: "دروس فردية مباشر (أونلاين)"
    }
  ];

  return (
    <div className='w-full h-auto mt-[200px] flex flex-col items-center justify-center relative lg:mt-[190px]'>
      <img
        src="Rectangle 43591.png"
        alt="Logo"
        className="mb-8 relative lg:h-[29rem] h-[37rem] lg:h-none w-[100%]" // تصویر کے نیچے کچھ مارجن
      /> 
      <div className="flex flex-col items-center justify-center mx-auto absolute mb-[4rem] lg:mb-8">
        <h1 className="text-center lg:text-3xl text-xl font-bold mb-9 flex items-center justify-center">
          هو الاختيار المناسب
          <img
            src="Frame 1171275990.png"
            alt="Platform logo"
            className="mx-2 border-[1px] border-[#AF87F4] h-11 object-contain rounded-3xl w-20 px-2" // امیج کی چوڑائی اور اونچائی کم کر دی گئی
          /> 
          اختيارك لمنصة
        </h1>


<div className="grid lg:grid-cols-2 gap-4">
  {images.map((image, index) => (
    <div key={index} className="p-[1px] rounded-2xl bg-gradient-to-b from-[#AF87F4] to-[#E1CFFF]">
      <div className="flex flex-row-reverse items-center lg:w-[510px] w-[390px] h-auto rounded-2xl bg-white">
        <img
          src={image.src}
          alt={`Relevant icon ${index + 1}`}
          className="w-28 h-24 pr-2"
        />
        <div className="flex flex-col">
          <h2
            className={`lg:text-lg text-sm font-extrabold lg:font-bold text-end ${
              image.title === "دروس فردية مباشر (أونلاين)" ? 'font-bold' : ''
            }`}
          >
            {image.title}
          </h2>
          <p className="text-end pl-5 text-sm lg:text-base">{image.text}</p>
        </div>
      </div>
    </div>
  ))}
</div>

      </div>
    </div>
  );
}

export default Platform;
