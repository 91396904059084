import React from 'react';
import Packages1 from './Packages1';
import Packages2 from './Packages2';
// import Packages3 from './Packages3';
// import Packages4 from './Packages4';
import Packages5 from './Packages5';

const VariousPackages = () => {
    

    return (
        <>
        <Packages1/>
        <Packages2/>
        {/* <Packages3/> */}
        {/* <Packages4/> */}
        <Packages5/>
        </>
    );
};

export default VariousPackages;



