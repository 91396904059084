
import React from 'react';
import Platform from '../Platform';
import Frequently from '../Frequently';
import VariousPackages from '../VariousPackages';
import Home from '../Home';
// import Important from '../Important';
import NavBar from '../Navbar';
import Footer from '../Footer';
// import Popop from '../Popop';

const MainHome = () => {
  return (
    <>
      <NavBar />
      {/* <Popop/>  */}
      <section id="home" className='h-[39rem] md:h-[750px]'>
        <Home />
      </section>
      <section id="platform">
        <Platform />
      </section>
      <section id="packages">
        <VariousPackages />
      </section>
      <section id="faq">
        <Frequently />
      </section>
      <section id="important">
        {/* <Important /> */}
      </section>
      <Footer /> 
    </>
  );
};

export default MainHome;