

// import React, { useState, useEffect } from 'react';

// const Popop = () => {
//   const [showPopop, setShowPopop] = useState(true);

//   // Show pop-up when page refreshes
//   useEffect(() => {
//     setShowPopop(true);
    
//     // Auto-close pop-up after 10 seconds
//     const timer = setTimeout(() => {
//       setShowPopop(true);
//     }, 10000); // 10 seconds

//     // Cleanup timer on unmount
//     return () => clearTimeout(timer);
//   }, []);

//   // Function to close the pop-up
//   const handleClose = () => {
//     setShowPopop(false);
//   };

//   // Function to open the pop-up manually
//   const handleOpen = () => {
//     setShowPopop(true);
//   };

//   return (
//     <div className="relative">
//       {/* Button to manually trigger the popup */}
//       <button
//         onClick={handleOpen}
//         className="text-white px-4 py-2 rounded-lg mt-4 z-20"
//       >
//         Show Pop-up
//       </button>

//       {showPopop && (
//         <div>
//           <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 text-right z-10 font-inter">
//             <div className="flex flex-col items-center w-[120%] mt-24 lg:mt-0">
//               {/* Close Button */}
//               <div className='relative h-[61px] w-[65%]'>
//                 <button
//                   onClick={handleClose}
//                   className="absolute top-[-1rem] right-4 w-10 h-10 rounded-full flex items-center justify-center shadow-lg text-lg font-bold mr-[95px] lg:mr-[-4px] md:mr-[205px] lg:mt-[271px] mt-[79px] bg-white"
//                 >
//                   ×
//                 </button>
//               </div>
//               <div className="bg-white w-[65%] h-[660px] p-8 rounded-3xl shadow-lg overflow-y-auto lg:mt-60 mt-[3rem]">
                
//                 {/* Main Content */}
//                 <h1 className=" font-semibold text-xl mb-5">حقوق الملكية الفكرية وحقوق النشر</h1>

                

//                 {/* <h2 className="font-bold text-lg mb-5"> حقوق الملكية:</h2>

//                 <p className="mb-9">
//                 تعد منصة هاللو وجميع محتوياتها محمية بموجب أنظمة حقوق النشر والعلامات التجارية وحقوق الملكية الفكرية في المملكة العربية السعودية. يُمنع أي شخص من استخدام أو تعديل أو نسخ أو نشر أو توزيع أي محتويات من هذه المنصة لأغراض تجارية دون الحصول على إذن خطي مسبق من إدارة المنصة. كما أن جميع الرسومات والصور الموجودة محمية بموجب حقوق النشر ولا يجوز استنساخها دون إذن مسبق.
//                 </p> */}
//                 <h1 className="font-bold text-lg mb-3">
//                   : حقوق الملكية               
//                 </h1>
//                 <p className="mb-3">
//                     تعد منصة هاللو وجميع محتوياتها محمية بموجب أنظمة حقوق النشر والعلامات التجارية وحقوق الملكية الفكرية في المملكة العربية السعودية. يُمنع أي شخص من استخدام أو تعديل أو نسخ أو نشر أو توزيع أي محتويات من هذه المنصة لأغراض تجارية دون الحصول على إذن خطي مسبق من إدارة المنصة. كما أن جميع الرسومات والصور الموجودة محمية بموجب حقوق النشر ولا يجوز استنساخها دون إذن مسبق.

//                 </p>
//                 <h1 className="font-bold text-lg mb-3">
//                   : التزام هاللو بحقوق الملكية الفكرية عند النشر 
//                 </h1>
//                 <p className="mb-3">
//                    تلتزم منصة هاللو بحماية حقوق الملكية الفكرية عند رفع المحتويات على أنظمتها الإلكترونية، سواء كانت نصوصًا أو صورًا أو برامجًا أو غيرها
//                 </p>
//                 <h1 className="font-bold text-lg mb-3">
//                    : الالتزام بسياسة حفظ واستخدام البيانات
                     
//                 </h1>
//                 <p className="mb-3">
//                     تستخدم منصة هاللو أنظمة إلكترونية مثل الفصول الافتراضية، وتخضع البيانات الشخصية وسياسة الاستخدام لحقوق الملكية الفكرية والخصوصية المطبقة في المملكة العربية السعودية.


//                 </p>
//                 <h1 className="font-bold text-lg mb-3">
//                    : إخلاء المسؤولية

//                 </h1>
//                 <p className="mb-3">تبذل منصة هاللو جهودها لتقديم خدمات الحجز والتدريس بأفضل جودة ممكنة، ولكنها غير مسؤولة عن أي أعطال تقنية أو حوادث خارجة عن سيطرتها. الخدمات المقدمة بدون أي ضمانات.</p>
//             <h1 className="font-bold text-lg mb-3"> : حدود المسؤولية
//             </h1>

//             <p className="mb-3">الخدمات المقدمة من خلال منصة هاللو لتسهيل العملية التعليمية قد تتعرض للتدخل أو الاعتراض من قبل أطراف أخرى، كما لا تتحمل المنصة مسؤولية المحتوى المقدم من المعلمين.</p>
//             <h1 className="font-bold text-lg mb-3"> : شروط الاستخدام </h1>

//             <p className="mb-3">ستخدام منصة هاللو يخضع لشروط الاستخدام والقوانين المطبقة في المملكة العربية السعودية، وأي تحديثات تطرأ على هذه الشروط يتم اعتمادها من قبل إدارة المنصة.</p>
//             <h1 className="font-bold text-lg mb-3"> : المرجعية القضائية </h1>

//             <p className="mb-3">أي نزاعات أو مطالبات تنشأ عن استخدام منصة هاللو تخضع للسلطات القضائية في المملكة العربية السعودية.</p>
//             <h1 className="font-bold text-lg mb-3"> : التزام المستخدمين والمعلمين </h1>

//             <p className="mb-3">يتعهد مستخدمو منصة هاللو بالامتثال لجميع القوانين المحلية وسياسات المنصة، وبأن تكون المشاركة عبر المنصة في إطار القوانين المعمول بها.</p>
//             <h1 className="font-bold text-lg mb-3"> : كلمة المرور </h1>

//             <p className="mb-3">أنت تتحمل المسؤولية كاملة عن جميع الأنشطة والأضرار التي قد تنتج عن عدم الإدارة الجيدة لكلمات المرور وعدم الحفاظ على سريتها. إذا حصلت أي عملية دخول غير مصرح بها أو كنت تعتقد بوجود خرق لأمان المنصة، يجب عليك إبلاغ مركز دعم منصة هاللو على الفور. لا تتحمل المنصة أي مسؤولية عن الأضرار أو الخسائر الناتجة عن عدم الحفاظ على سرية كلمة المرور.</p>
//             <h1 className="font-bold text-lg mb-3"> : معلومات الحساب </h1>

//             <p className="mb-3">أنت توافق على إبقاء معلومات حسابك محدثة دائمًا، بما في ذلك معلومات بريدك الإلكتروني ووسائل الاتصال وتفاصيل وسيلة الدفع المناسبة.</p>
//             <h1 className="font-bold text-lg mb-3"> : نقل ملكية الحساب وحفظه </h1>

//             <p className="mb-3">لا يمكنك نقل أو بيع حسابك أو اسم المستخدم الخاص بك على منصة هاللو لأي أطراف أخرى. أنت مسؤول عن جميع الأنشطة المرتبطة بحسابك أو باسم المستخدم الخاص بك.
//             </p>
//             <h1 className="font-bold text-lg mb-3"> : أحقية رفض الخدمة </h1>

//             <p className="mb-3">يحق لمنصة هاللو، وفقًا لتقديرها، إيقاف أي حساب ينتهك بنود هذه الاتفاقية أو يخالف أي قوانين معمول بها في الدولة التي يقيم بها المستخدم. جميع الحقوق محفوظة للمنصة لرفض تقديم الخدمة لأي شخص ولأي سبب في أي وقت.</p>
//             <h1 className="font-bold text-lg mb-3"> : اتفاقية المستخدم للمحتوى </h1>

//             <p className="mb-3">عند حجز درس أو جلسة عبر منصة هاللو، سيكون لديك القدرة على الوصول إلى المحتوى المرتبط بالجسلة أو الدرس طالما أنه متاح على المنصة. قد تقوم المنصة، وفقًا لتقديرها، بإتاحة المحتوى لأجل غير مسمى، ولكن قد يتم حجب الوصول إليه في حال تم حذفه. نوصي بتحميل أي محتوى احتياطي لاستخدامه لاحقًا. المنصة غير مسؤولة عن استعادة المحتويات المحذوفة أو توفير نسخ منها.
//                 <br></br>أنت توافق على عدم مشاركة المحتوى مع الآخرين وعدم إعادة تحميله مجانًا على الإنترنت أو استخدامه لأغراض تجارية.
//                </p>
//             <h1 className="font-bold text-lg mb-3"> : الرسوم والتسعير والمدفوعات</h1>
            
            
//             <h1 className="font-bold text-lg mb-3"> : الرسوم </h1>
//             <p className="mb-3">تسجيل حساب في منصة هاللو مجاني لجميع المستخدمين. ومع ذلك، قد تقوم المنصة بتغيير هذه السياسة في المستقبل، أو تغيير بعض الخدمات. في حال تقديم خدمة جديدة، ستكون الرسوم فعالة بدءًا من إطلاقها. جميع المبالغ المعروضة ستكون بالعملة المحلية (مثل الريال السعودي). أنت مسؤول عن دفع الضرائب المفروضة إن وجدت.</p>
//             <h1 className="font-bold text-lg mb-3"> : تسعيرة الجلسات التعليمية أو الخدمات </h1>
//             <p className="mb-3">تحدد منصة هاللو أسعار الجلسات التعليمية أو الخدمات. قد تغير المنصة الأسعار من وقت لآخر سواء بالزيادة أو النقصان.</p>
//             <h1 className="font-bold text-lg mb-3"> : سياسة استرجاع الأموال </h1>
//             <p className="mb-3">جميع المدفوعات التي تتم عبر منصة هاللو نهائية ولا تقبل استردادها. ومع ذلك، في بعض الحالات المؤهلة وبحسب تقدير المنصة، قد ننظر في استرداد الأموال في الحالات التالية:</p>
//             {/* <p className="mb-9" style={{ direction: 'rtl', }}>خصم مبلغ بطريق الخطأ مرتين  .1</p> */}

//             <p className="mb-3 mr-5" style={{ direction: 'rtl', textAlign: 'right' }}>
//   <span style={{ float: 'right', marginLeft: '5px' }}>1.</span>
//   خصم مبلغ بطريق الخطأ مرتين
// </p>


// <ul className='mr-5 mb-3' style={{ listStyleType: 'disc', paddingRight: '20px',direction: 'rtl', textAlign: 'right' }}>
//     <li>جميع عمليات استرداد الأموال ستخضع لتدقيق وموافقة منصة هاللو قبل تنفيذها. في هذه الحالة، سيتم إعادة المبلغ بالطريقة التي تراها المنصة مناسبة، بما في ذلك إضافته إلى حساب المستخدم للاشتراك في خدمات أخرى</li>
//     <li>معرفة العتاد المناسب لتشغيل المحتوى هو مسؤولية المستخدم. لن تتم عمليات استرداد الأموال في حال عدم ملاءمة العتاد لتشغيل المحتوى.</li>
//     <li>ا تضمن منصة هاللو دقة أو صحة المعلومات المقدمة من خلال الدروس أو الجلسات، ولا يمكن تقديم طلبات استرداد بناءً على هذا الأساس.</li>
//     <li>ا تضمن المنصة أن المحتوى سيُلبي جميع احتياجات وتوقعات المستخدم، ولن يتم استرداد المبالغ المدفوعة إذا لم يجد المستخدم المحتوى مفيدًا.</li>
//     <li>لا يتم استرداد مبلغ الجلسة التعليمية في حال عدم اجتياز المستخدم متطلبات الدرس أو التدريب.</li>
// </ul>

//             {/* <h4>جميع طلبات استرداد الأموال يجب أن تتم عبر البريد الإلكتروني info@hallo.sa مع توضيح اسم الجلسة واسم المستخدم وتفاصيل السبب.</h4> */}
//             <p className='mb-3' style={{ direction: 'rtl', textAlign: 'right' }}>
//   <span>جميع طلبات استرداد الأموال يجب أن تتم عبر البريد الإلكتروني</span>
//   <span style={{ fontWeight: 'bold', marginRight: '5px' }}>info@hallo.sa</span>
//   <span>مع توضيح اسم الجلسة واسم المستخدم وتفاصيل السبب.</span>
// </p>

//             <h1 className="font-bold text-lg mb-3"> : نقل الحقوق</h1>
//             <p className='mb-3' >منصة هاللو لا تقدم خدمات نقل الحقوق والملكية من المعلم إلى الطالب أو العكس بتاتًا.<br/>جميع المواد والدروس التي تقدم من خلال المنصة هي للاستخدام الشخصي والتعلم، ولا يحق للطالب أو المعلم نقلها أو استخدامها لأغراض تجارية خارج إطار المنصة.
//                 سلوكيات الأعضاء والزوار:</p>
//                 <h1 className="font-bold text-lg mt-3 mb-3"> : سلوكيات الأعضاء والزوار</h1>
//                 <p className='mb-3 mr-5'> : كشرط لاستخدام الموقع، الأعضاء والزوار يوافقون على جميع البنود التالية</p>
                
//                 <ul className='mr-6 mb-3' style={{ listStyleType: 'disc', paddingRight: '20px', direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}><h4>يجب أن تكون عضوًا مسجلًا لحجز الدروس عبر منصة "هاللو".</h4></li>
//     <li style={{ marginBottom: '6px' }}><h3>يُطلب منك اختيار اسم مستخدم وكلمة مرور، وأنت مسؤول عن الحفاظ على سرية هذه المعلومات.</h3></li>
//     <li style={{ marginBottom: '6px' }}><h4>أنت مسؤول عن جميع النشاطات التي تجرى باستخدام حسابك.</h4></li>
//     <li style={{ marginBottom: '6px' }}><h3>توافق على أنك لن تساعد أو تشجع أي شخص يحاول اختراق أو التحايل على أمان النظام أو تعديل بنية النظام أو سياسات الموقع بأي شكل من الأشكال</h3></li>
//     <li style={{ marginBottom: '6px' }}><h3>يجب إبلاغ المنصة بأي محاولة لانتحال الشخصية أو اختراق الحسابات من خلال التواصل عبر صفحة "اتصل بنا" أو البريد الإلكتروني info@hallo.com.</h3></li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : الالتزامات</h1>

//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>لا يمكن استخدام الموقع لأغراض غير قانونية أو غير مصرح بها، بما في ذلك انتحال الشخصية، نشر محتوى ضار أو فيروسات، أو انتهاك حقوق الملكية الفكرية أو الخصوصية.</li>
//     <li style={{ marginBottom: '6px' }}>يُحظر رفع أو مشاركة أي محتوى مسيء أو مخالف للأخلاقيات العامة أو قوانين المملكة العربية السعودية.</li>
//     <li style={{ marginBottom: '6px' }} >يُمنع استخدام المنصة لأغراض غير تعليمية أو لاستغلال المستخدمين أو نشر إعلانات غير مصرحة.</li>
//     <li style={{ marginBottom: '6px' }}>يمنع نقل أي محتوى يحتوي على فيروسات أو برمجيات خبيثة تؤدي إلى تعطيل أجهزة المستخدمين أو النظام.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : المحتوى غير المناسب</h1>
//                 <ul className='mr-10' style={{ listStyleType: 'disc', paddingLeft: '20px', direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>منصة "هاللو" تحتفظ بحق حذف أو تعديل أي محتوى مخالف يتم رفعه من قبل المستخدمين.</li>
//     <li style={{ marginBottom: '6px' }}>المنصة غير مسؤولة عن أي محتوى يتم رفعه من قبل المستخدمين، ويجب على المستخدمين تحمل مسؤولية تعرضهم لأي محتوى غير مناسب.</li>
//     <li style={{ marginBottom: '6px' }}>قد تقوم "هاللو" بمراقبة المحتوى وحذف أو تعديل ما تراه مخالفًا أو غير مناسب.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3 mt-3"> : الروابط لمواقع أو مصادر أخرى
//                 </h1>
//                 <ul className='mr-10' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>الموقع قد يحتوي على روابط لمواقع خارجية.</li>
//     <li style={{ marginBottom: '6px' }}>منصة "هاللو" ليست مسؤولة عن دقة أو محتوى المواقع الخارجية ولا تتحمل أي مسؤولية قانونية تجاه الاعتماد على هذه الروابط.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : يتوجب على الأعضاء والزوار الامتناع عن
//                 </h1>

//                 <ul className='mr-10' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>انتهاك القوانين أو سياسات المنصة.</li>
//     <li style={{ marginBottom: '6px' }}>رفع أو مشاركة محتوى مسيء، غير قانوني، أو مضلل.</li>
//     <li style={{ marginBottom: '6px' }}>محاولة الوصول غير المصرح به للأنظمة أو حسابات الأعضاء الآخرين.</li>
//     <li style={{ marginBottom: '6px' }}>انتحال شخصية معلم أو طالب.</li>
//     <li style={{ marginBottom: '6px' }}>الترويج لخدمات مشابهة أو منافسة بدون تصريح من "هاللو".</li>
//     <li style={{ marginBottom: '6px' }}>رفع أو مشاركة فيروسات أو برمجيات خبيثة.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3 mt-3">حقوق الطبع والنشر</h1>
//                 <p className='mb-[6px]'>  . منصة "هاللو" تحترم حقوق الملكية الفكرية للآخرين
//                 </p>
                
//                 <p className='mb-3'> . أي محتوى غير مرخص قد يُحذف، وقد تُنهي المنصة حسابات الأعضاء الذين يخالفون هذه السياسات بانتظام

//                 </p>
//                 <h1 className="font-bold text-lg mb-3">سياسة الدعم</h1>
//                 <p className='mt-3 mb-3'> . أي محتوى غير مرخص قد يُحذف، وقد تُنهي المنصة حسابات الأعضاء الذين يخالفون هذه السياسات بانتظام
//                 </p>

//                 <ul className='mr-10' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>تطوير وتحسين تجربة الاستخدام.</li>
//     <li style={{ marginBottom: '6px' }}>توفير الدعم الفني خلال الحصص.</li>
//     <li style={{ marginBottom: '6px' }}>توجيه المستخدمين حول كيفية استخدام النظام ودفع الرسوم وتحميل الشهادات.</li>
// </ul>

//                 <h1 className="font-bold text-lg mt-3 mb-3"> : التعويض</h1>

//                 <p className='mb-3'>  أنت تقر بتعويض منصة "هاللو" عن أي دعاوى أو التزامات تنشأ نتيجة لاستخدامك للمنصة أو انتهاكك لأي قوانين أو حقوق</p>

//                 <h1 className="font-bold text-lg mb-3">إخلاء المسؤولية من الضمان:</h1>
//                 <p className='mb-3'>منصة "هاللو" تقدم خدماتها كما هي، ولا تضمن نتائج محددة من استخدام النظام. استخدامك للخدمة هو تحت مسؤوليتك الشخصية.</p>

//                 <h1 className="font-bold text-lg mb-3">سياسة الخصوصية</h1>

//                 <p className="mb-3">تمثل سياسة الخصوصية الحالية ("السياسة") الخطوط الإرشادية الداخلية الخاصة بإدارة وموظفي ومتعاقدي منصة "هاللو" (www.hallo.sa)، والتي تتعلق بالتعامل مع معلومات المستخدمين، بما يشمل المعلومات الشخصية وغير الشخصية. عندما يقوم المستخدمون بتقديم ومشاركة معلومات معينة، يُعد بعضها شخصياً وبعضها الآخر معلومة عامة (تعرف مجتمعة باسم "معلومات المستخدِم").<br/><br/>تهدف هذه السياسة إلى توضيح كيفية تعاملنا مع المعلومات الشخصية وغير الشخصية التي قد نجمعها من خلال تفاعلك مع منصتنا. نحن نحترم خصوصيتك ونسعى إلى توفير حماية لبياناتك بناءً على القوانين والأنظمة المعمول بها.<br/><br/>جمع المعلومات الشخصية وغير الشخصية</p>
//                 <h1 className="font-bold text-lg mb-3"> : المعلومات التعريفية غير الشخصية</h1>

//                 <p className="mb-3">تجمع منصة "هاللو" معلومات تعريفية غير شخصية مثل نوع المتصفح، الموقع الذي زرت منه المنصة، وقت وتاريخ الزيارة، وذلك لتحسين تجربتك وفهم سلوك المستخدمين. قد نقوم بنشر معلومات إحصائية حول سلوك المستخدمين مثل الدروس الأكثر حجزًا، ولكن لن نعرض أي معلومات شخصية.</p>
//                 <h1 className="font-bold text-lg mb-3"> : المعلومات الشخصية التعريفية
//                 </h1>

//                 <p className="mb-3">نحن ملتزمون بحماية معلوماتك الشخصية، والتي قد تشمل اسمك، عنوان بريدك الإلكتروني، رقم هاتفك، تفاصيل الدفع، وغيرها من المعلومات الضرورية لتقديم خدمات الحجز والدروس عبر منصتنا.<br/><br/> : نحن نجمع هذه المعلومات للأغراض التالية</p>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>تمكينك من الحجز مع المدرسين وتنفيذ المدفوعات.</li>
//     <li style={{ marginBottom: '6px' }}>تخصيص المحتوى والخدمات بما يتناسب مع احتياجاتك.</li>
//     <li style={{ marginBottom: '6px' }}>إرسال إشعارات وإعلانات تتعلق بخدمات المنصة.</li>
//     <li style={{ marginBottom: '6px' }}>استطلاع رأيك حول تجربتك في المنصة وتحسين خدماتنا.</li>
// </ul>


//                 <h1 className="font-bold text-lg mb-3">مشاركة المعلومات مع الغير</h1>
//                 <p className="">قد نشارك بعض المعلومات مع الأطراف الثالثة المرتبطة بنا، بما في ذلك المدرسين الذين يتم حجز الدروس معهم، أو مقدمي الخدمات الآخرين مثل بوابات الدفع الإلكترونية. نحن نتعاون أيضًا مع السلطات القانونية في حال كانت هناك حاجة قانونية لمشاركة معلومات المستخدم في سياق التحقيقات أو الدعاوى القضائية</p><br/><br/>
//                 <h1 className="font-bold text-lg mb-3 mt-[-32px]">أمن المعلومات</h1>

//                 <p className="mb-5">نحن نلتزم باتباع المعايير المقبولة عمومًا لحماية معلومات المستخدمين. ورغم أن أي وسيلة نقل عبر الإنترنت أو تخزين إلكتروني لا يمكن أن تكون آمنة تمامًا، فإننا نسعى لتطبيق أفضل الوسائل المتاحة لضمان حماية بياناتك الشخصية.</p>
//                 <h1 className="font-bold text-lg mb-3"> : ملفات الكوكيز</h1>
                
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//                 <li style={{ marginBottom: '6px' }}>منصة هاللو تستخدم بيانات ملفات الكوكيز لمعرفة وتتبع المستخدمين وطريقة استخدامهم للمنصة، بما في ذلك نوع المتصفح ونظام التشغيل ومزود الخدمة وتفضيلات الوصول للموقع. هذه المعلومات لا تتضمن تفاصيل شخصية، وتساعد في تحسين تجربة المستخدم. إذا كنت لا ترغب في تخزين ملفات الكوكيز، يمكنك تعطيلها من إعدادات المتصفح.</li>
//                 <li style={{ marginBottom: '6px' }}>ملاحظة: بعض ميزات منصة هاللو قد لا تعمل بشكل صحيح بدون الكوكيز.</li>
//                 </ul>


//                 <h1 className="font-bold text-lg mb-3"> : عنوان الإنترنت IP</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>تُستخدم عناوين الإنترنت (IP) لتتبع وتحليل كيفية استخدام الزوار للمنصة، وذلك لتحسين تجربة المستخدم وتطوير الخدمات المقدمة. إذا كنت لا ترغب في السماح بتتبع هذه البيانات، يمكنك ضبط إعدادات المتصفح وفقًا لذلك.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : الأمان
//                 </h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>جميع المعلومات الشخصية وغير الشخصية التي نحصل عليها محفوظة ضد الوصول غير المصرح له.</li>
//     <li style={{ marginBottom: '6px' }}>يتم الكشف عن هذه المعلومات فقط عندما يكون ذلك مطلوبًا بموجب القانون أو لحماية حقوق المستخدمين والمنصة.</li>
//     <li style={{ marginBottom: '6px' }}>المعلومات المالية، مثل تفاصيل الدفع، تُخزن بشكل آمن ومشفّر.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : المعلومات المالية</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>عند إجراء أي عمليات دفع، يجب إدخال المعلومات الصحيحة بما في ذلك الاسم القانوني.</li>
//     <li style={{ marginBottom: '6px' }}>جميع المدفوعات والمعلومات المالية تحفظ بسرية تامة ولا يمكن الوصول إليها إلا لموظفي منصة هاللو أو الوسيط المالي المستخدم. لن يتم الإفصاح عن هذه المعلومات إلا عند الحاجة القانونية.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3"> : الروابط لمواقع أخرى</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>منصة هاللو لا تتحمل المسؤولية عن المحتوى أو الخدمات في مواقع الطرف الثالث. ننصح المستخدمين بقراءة سياسات الخصوصية والشروط الخاصة بأي موقع خارجي قبل استخدامه.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-5"> : الدعم والمساندة الأكاديمية</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//     <li style={{ marginBottom: '6px' }}>تقدم منصة هاللو دعمًا فنيًا وتعليميًا للطلاب والمدرسين أثناء تنفيذ الدروس عبر القاعات الافتراضية. يوجد مدير للبرنامج يساعد في التنظيم وحل المشكلات التقنية للطلاب.</li>
// </ul>

//                 <h1 className="font-bold text-lg mb-3">: الدعم والمساندة الأكاديمية</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//                     <li style={{ marginBottom: '6px' }}>تتبنى منصة هاللو سياسات النزاهة الأكاديمية لضمان التزام الطلاب بحضور الحصص بشكل منتظم والتفاعل مع المدرسين. </li>
//                 </ul>
//                 <h1 className="font-bold text-lg mb-5"> : آلية التحقق من هوية المستخدم</h1>
//                 <ul className='mr-10 mb-3' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
//                     <li style={{ marginBottom: '6px' }}>يتحقق نظام "هاللو" من هوية المستخدم من خلال خطوات مثل إرسال رسالة تأكيد عبر البريد الإلكتروني أو الهاتف المحمول. </li>
//                     <li style={{ marginBottom: '6px' }}>يمكن للمستخدمين تعديل بياناتهم فقط بعد التحقق الأمني اللازم.</li>
//                 </ul>

                

                

               
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Popop;




import React, { useState, useEffect, useRef } from 'react';

const Popop = () => {
  const [showPopop, setShowPopop] = useState(true);
  const popopRef = useRef(null);

  // Show pop-up when page refreshes
  useEffect(() => {
    setShowPopop(true);

    // Auto-close pop-up after 10 seconds
    const timer = setTimeout(() => {
      setShowPopop(true);
    }, 10000); // 10 seconds

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);

  // Close the pop-up if click is outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popopRef.current && !popopRef.current.contains(event.target)) {
        setShowPopop(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to close the pop-up
  const handleClose = () => {
    setShowPopop(false);
  };

  // Function to open the pop-up manually
  const handleOpen = () => {
    setShowPopop(true);
  };



  return (
    <div className="relative">
      {/* Button to manually trigger the popup */}
      <button
        onClick={handleOpen}
        className="text-white px-4 py-2 rounded-lg mt-4 z-20"
      >
        Show Pop-up
      </button>

      {showPopop && (
        <div >
          <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 text-right z-10 font-inter">
            <div className="flex flex-col items-center w-[120%] mt-24 lg:mt-0">
              {/* Close Button */}
              <div className='relative h-[61px] w-[65%]'>
                <button
                  onClick={handleClose}
                  className="absolute top-[-1rem] right-4 w-10 h-10 rounded-full flex items-center justify-center shadow-lg text-lg font-bold mr-[95px] lg:mr-[-4px] md:mr-[205px] lg:mt-[271px] mt-[79px] bg-white"
                >
                  ×
                </button>
              </div>
              <div ref={popopRef} className="bg-white w-[65%] h-[660px] p-8 rounded-3xl shadow-lg overflow-y-auto lg:mt-60 mt-[3rem]">
                
                {/* Main Content */}
                <h1 className=" font-bold text-md mb-5">حقوق الملكية الفكرية وحقوق النشر</h1>

                

                {/* <h2 className="font-bold text-lg mb-5"> حقوق الملكية:</h2>

                <p className="mb-9">
                تعد منصة هاللو وجميع محتوياتها محمية بموجب أنظمة حقوق النشر والعلامات التجارية وحقوق الملكية الفكرية في المملكة العربية السعودية. يُمنع أي شخص من استخدام أو تعديل أو نسخ أو نشر أو توزيع أي محتويات من هذه المنصة لأغراض تجارية دون الحصول على إذن خطي مسبق من إدارة المنصة. كما أن جميع الرسومات والصور الموجودة محمية بموجب حقوق النشر ولا يجوز استنساخها دون إذن مسبق.
                </p> */}
                <h1 className="font-bold text-md mb-3">
                  : حقوق الملكية               
                </h1>
                <p className="mb-3 text-sm">
                    تعد منصة هاللو وجميع محتوياتها محمية بموجب أنظمة حقوق النشر والعلامات التجارية وحقوق الملكية الفكرية في المملكة العربية السعودية. يُمنع أي شخص من استخدام أو تعديل أو نسخ أو نشر أو توزيع أي محتويات من هذه المنصة لأغراض تجارية دون الحصول على إذن خطي مسبق من إدارة المنصة. كما أن جميع الرسومات والصور الموجودة محمية بموجب حقوق النشر ولا يجوز استنساخها دون إذن مسبق.

                </p>
                <h1 className="font-bold text-md mb-3">
                  : التزام هاللو بحقوق الملكية الفكرية عند النشر 
                </h1>
                <p className="mb-3 text-sm">
                   تلتزم منصة هاللو بحماية حقوق الملكية الفكرية عند رفع المحتويات على أنظمتها الإلكترونية، سواء كانت نصوصًا أو صورًا أو برامجًا أو غيرها
                </p>
                <h1 className="font-bold text-md mb-3">
                   : الالتزام بسياسة حفظ واستخدام البيانات
                     
                </h1>
                <p className="mb-3 text-sm">
                    تستخدم منصة هاللو أنظمة إلكترونية مثل الفصول الافتراضية، وتخضع البيانات الشخصية وسياسة الاستخدام لحقوق الملكية الفكرية والخصوصية المطبقة في المملكة العربية السعودية.


                </p>
                <h1 className="font-bold text-md mb-3">
                   : إخلاء المسؤولية

                </h1>
                <p className="mb-3 text-sm">تبذل منصة هاللو جهودها لتقديم خدمات الحجز والتدريس بأفضل جودة ممكنة، ولكنها غير مسؤولة عن أي أعطال تقنية أو حوادث خارجة عن سيطرتها. الخدمات المقدمة بدون أي ضمانات.</p>
            <h1 className="font-bold text-md mb-3"> : حدود المسؤولية
            </h1>

            <p className="mb-3 text-sm">الخدمات المقدمة من خلال منصة هاللو لتسهيل العملية التعليمية قد تتعرض للتدخل أو الاعتراض من قبل أطراف أخرى، كما لا تتحمل المنصة مسؤولية المحتوى المقدم من المعلمين.</p>
            <h1 className="font-bold text-md mb-3"> : شروط الاستخدام </h1>

            <p className="mb-3 text-sm">ستخدام منصة هاللو يخضع لشروط الاستخدام والقوانين المطبقة في المملكة العربية السعودية، وأي تحديثات تطرأ على هذه الشروط يتم اعتمادها من قبل إدارة المنصة.</p>
            <h1 className="font-bold text-md mb-3"> : المرجعية القضائية </h1>

            <p className="mb-3 text-sm">أي نزاعات أو مطالبات تنشأ عن استخدام منصة هاللو تخضع للسلطات القضائية في المملكة العربية السعودية.</p>
            <h1 className="font-bold text-md mb-3"> : التزام المستخدمين والمعلمين </h1>

            <p className="mb-3 text-sm">يتعهد مستخدمو منصة هاللو بالامتثال لجميع القوانين المحلية وسياسات المنصة، وبأن تكون المشاركة عبر المنصة في إطار القوانين المعمول بها.</p>
            <h1 className="font-bold text-md mb-3"> : كلمة المرور </h1>

            <p className="mb-3 text-sm">أنت تتحمل المسؤولية كاملة عن جميع الأنشطة والأضرار التي قد تنتج عن عدم الإدارة الجيدة لكلمات المرور وعدم الحفاظ على سريتها. إذا حصلت أي عملية دخول غير مصرح بها أو كنت تعتقد بوجود خرق لأمان المنصة، يجب عليك إبلاغ مركز دعم منصة هاللو على الفور. لا تتحمل المنصة أي مسؤولية عن الأضرار أو الخسائر الناتجة عن عدم الحفاظ على سرية كلمة المرور.</p>
            <h1 className="font-bold text-md mb-3"> : معلومات الحساب </h1>

            <p className="mb-3 text-sm">أنت توافق على إبقاء معلومات حسابك محدثة دائمًا، بما في ذلك معلومات بريدك الإلكتروني ووسائل الاتصال وتفاصيل وسيلة الدفع المناسبة.</p>
            <h1 className="font-bold text-md mb-3"> : نقل ملكية الحساب وحفظه </h1>

            <p className="mb-3 text-sm">لا يمكنك نقل أو بيع حسابك أو اسم المستخدم الخاص بك على منصة هاللو لأي أطراف أخرى. أنت مسؤول عن جميع الأنشطة المرتبطة بحسابك أو باسم المستخدم الخاص بك.
            </p>
            <h1 className="font-bold text-md mb-3"> : أحقية رفض الخدمة </h1>

            <p className="mb-3 text-sm">يحق لمنصة هاللو، وفقًا لتقديرها، إيقاف أي حساب ينتهك بنود هذه الاتفاقية أو يخالف أي قوانين معمول بها في الدولة التي يقيم بها المستخدم. جميع الحقوق محفوظة للمنصة لرفض تقديم الخدمة لأي شخص ولأي سبب في أي وقت.</p>
            <h1 className="font-bold text-md mb-3"> : اتفاقية المستخدم للمحتوى </h1>

            <p className="mb-3">عند حجز درس أو جلسة عبر منصة هاللو، سيكون لديك القدرة على الوصول إلى المحتوى المرتبط بالجسلة أو الدرس طالما أنه متاح على المنصة. قد تقوم المنصة، وفقًا لتقديرها، بإتاحة المحتوى لأجل غير مسمى، ولكن قد يتم حجب الوصول إليه في حال تم حذفه. نوصي بتحميل أي محتوى احتياطي لاستخدامه لاحقًا. المنصة غير مسؤولة عن استعادة المحتويات المحذوفة أو توفير نسخ منها.
                <br></br>أنت توافق على عدم مشاركة المحتوى مع الآخرين وعدم إعادة تحميله مجانًا على الإنترنت أو استخدامه لأغراض تجارية.
               </p>
            <h1 className="font-bold text-md mb-3"> : الرسوم والتسعير والمدفوعات</h1>
            
            
            <h1 className="font-bold text-md mb-3"> : الرسوم </h1>
            <p className="mb-3 text-sm">تسجيل حساب في منصة هاللو مجاني لجميع المستخدمين. ومع ذلك، قد تقوم المنصة بتغيير هذه السياسة في المستقبل، أو تغيير بعض الخدمات. في حال تقديم خدمة جديدة، ستكون الرسوم فعالة بدءًا من إطلاقها. جميع المبالغ المعروضة ستكون بالعملة المحلية (مثل الريال السعودي). أنت مسؤول عن دفع الضرائب المفروضة إن وجدت.</p>
            <h1 className="font-bold text-md mb-3"> : تسعيرة الجلسات التعليمية أو الخدمات </h1>
            <p className="mb-3 text-sm">تحدد منصة هاللو أسعار الجلسات التعليمية أو الخدمات. قد تغير المنصة الأسعار من وقت لآخر سواء بالزيادة أو النقصان.</p>
            <h1 className="font-bold text-md mb-3"> : سياسة استرجاع الأموال </h1>
            <p className="mb-3 text-sm">جميع المدفوعات التي تتم عبر منصة هاللو نهائية ولا تقبل استردادها. ومع ذلك، في بعض الحالات المؤهلة وبحسب تقدير المنصة، قد ننظر في استرداد الأموال في الحالات التالية:</p>
            {/* <p className="mb-9" style={{ direction: 'rtl', }}>خصم مبلغ بطريق الخطأ مرتين  .1</p> */}

            <p className="mb-3 mr-5 text-sm" style={{ direction: 'rtl', textAlign: 'right' }}>
  <span style={{ float: 'right', marginLeft: '5px' }}>1.</span>
  خصم مبلغ بطريق الخطأ مرتين
</p>


<ul className='mr-5 mb-3 text-sm' style={{ listStyleType: 'disc', paddingRight: '20px',direction: 'rtl', textAlign: 'right' }}>
    <li>جميع عمليات استرداد الأموال ستخضع لتدقيق وموافقة منصة هاللو قبل تنفيذها. في هذه الحالة، سيتم إعادة المبلغ بالطريقة التي تراها المنصة مناسبة، بما في ذلك إضافته إلى حساب المستخدم للاشتراك في خدمات أخرى</li>
    <li>معرفة العتاد المناسب لتشغيل المحتوى هو مسؤولية المستخدم. لن تتم عمليات استرداد الأموال في حال عدم ملاءمة العتاد لتشغيل المحتوى.</li>
    <li>ا تضمن منصة هاللو دقة أو صحة المعلومات المقدمة من خلال الدروس أو الجلسات، ولا يمكن تقديم طلبات استرداد بناءً على هذا الأساس.</li>
    <li>ا تضمن المنصة أن المحتوى سيُلبي جميع احتياجات وتوقعات المستخدم، ولن يتم استرداد المبالغ المدفوعة إذا لم يجد المستخدم المحتوى مفيدًا.</li>
    <li>لا يتم استرداد مبلغ الجلسة التعليمية في حال عدم اجتياز المستخدم متطلبات الدرس أو التدريب.</li>
</ul>

            {/* <h4>جميع طلبات استرداد الأموال يجب أن تتم عبر البريد الإلكتروني info@hallo.sa مع توضيح اسم الجلسة واسم المستخدم وتفاصيل السبب.</h4> */}
            <p className='mb-3 text-sm' style={{ direction: 'rtl', textAlign: 'right' }}>
  <span>جميع طلبات استرداد الأموال يجب أن تتم عبر البريد الإلكتروني</span>
  <span style={{ fontWeight: 'bold', marginRight: '5px' }}>info@hallo.sa</span>
  <span>مع توضيح اسم الجلسة واسم المستخدم وتفاصيل السبب.</span>
</p>

            <h1 className="font-bold text-md mb-3"> : نقل الحقوق</h1>
            <p className='mb-3 text-sm' >منصة هاللو لا تقدم خدمات نقل الحقوق والملكية من المعلم إلى الطالب أو العكس بتاتًا.<br/>جميع المواد والدروس التي تقدم من خلال المنصة هي للاستخدام الشخصي والتعلم، ولا يحق للطالب أو المعلم نقلها أو استخدامها لأغراض تجارية خارج إطار المنصة.
                سلوكيات الأعضاء والزوار:</p>
                <h1 className="font-bold text-md mt-3 mb-3"> : سلوكيات الأعضاء والزوار</h1>
                <p className='mb-3 mr-5 text-sm'> : كشرط لاستخدام الموقع، الأعضاء والزوار يوافقون على جميع البنود التالية</p>
                
                <ul className='mr-6 mb-3 text-sm' style={{ listStyleType: 'disc', paddingRight: '20px', direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}><h4>يجب أن تكون عضوًا مسجلًا لحجز الدروس عبر منصة "هاللو".</h4></li>
    <li style={{ marginBottom: '6px' }}><h3>يُطلب منك اختيار اسم مستخدم وكلمة مرور، وأنت مسؤول عن الحفاظ على سرية هذه المعلومات.</h3></li>
    <li style={{ marginBottom: '6px' }}><h4>أنت مسؤول عن جميع النشاطات التي تجرى باستخدام حسابك.</h4></li>
    <li style={{ marginBottom: '6px' }}><h3>توافق على أنك لن تساعد أو تشجع أي شخص يحاول اختراق أو التحايل على أمان النظام أو تعديل بنية النظام أو سياسات الموقع بأي شكل من الأشكال</h3></li>
    <li style={{ marginBottom: '6px' }}><h3>يجب إبلاغ المنصة بأي محاولة لانتحال الشخصية أو اختراق الحسابات من خلال التواصل عبر صفحة "اتصل بنا" أو البريد الإلكتروني info@hallo.com.</h3></li>
</ul>

                <h1 className="font-bold text-md mb-3"> : الالتزامات</h1>

                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>لا يمكن استخدام الموقع لأغراض غير قانونية أو غير مصرح بها، بما في ذلك انتحال الشخصية، نشر محتوى ضار أو فيروسات، أو انتهاك حقوق الملكية الفكرية أو الخصوصية.</li>
    <li style={{ marginBottom: '6px' }}>يُحظر رفع أو مشاركة أي محتوى مسيء أو مخالف للأخلاقيات العامة أو قوانين المملكة العربية السعودية.</li>
    <li style={{ marginBottom: '6px' }} >يُمنع استخدام المنصة لأغراض غير تعليمية أو لاستغلال المستخدمين أو نشر إعلانات غير مصرحة.</li>
    <li style={{ marginBottom: '6px' }}>يمنع نقل أي محتوى يحتوي على فيروسات أو برمجيات خبيثة تؤدي إلى تعطيل أجهزة المستخدمين أو النظام.</li>
</ul>

                <h1 className="font-bold text-md mb-3"> : المحتوى غير المناسب</h1>
                <ul className='mr-10 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px', direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>منصة "هاللو" تحتفظ بحق حذف أو تعديل أي محتوى مخالف يتم رفعه من قبل المستخدمين.</li>
    <li style={{ marginBottom: '6px' }}>المنصة غير مسؤولة عن أي محتوى يتم رفعه من قبل المستخدمين، ويجب على المستخدمين تحمل مسؤولية تعرضهم لأي محتوى غير مناسب.</li>
    <li style={{ marginBottom: '6px' }}>قد تقوم "هاللو" بمراقبة المحتوى وحذف أو تعديل ما تراه مخالفًا أو غير مناسب.</li>
</ul>

                <h1 className="font-bold text-md mb-3 mt-3"> : الروابط لمواقع أو مصادر أخرى
                </h1>
                <ul className='mr-10 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>الموقع قد يحتوي على روابط لمواقع خارجية.</li>
    <li style={{ marginBottom: '6px' }}>منصة "هاللو" ليست مسؤولة عن دقة أو محتوى المواقع الخارجية ولا تتحمل أي مسؤولية قانونية تجاه الاعتماد على هذه الروابط.</li>
</ul>

                <h1 className="font-bold text-md mb-3"> : يتوجب على الأعضاء والزوار الامتناع عن
                </h1>

                <ul className='mr-10 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>انتهاك القوانين أو سياسات المنصة.</li>
    <li style={{ marginBottom: '6px' }}>رفع أو مشاركة محتوى مسيء، غير قانوني، أو مضلل.</li>
    <li style={{ marginBottom: '6px' }}>محاولة الوصول غير المصرح به للأنظمة أو حسابات الأعضاء الآخرين.</li>
    <li style={{ marginBottom: '6px' }}>انتحال شخصية معلم أو طالب.</li>
    <li style={{ marginBottom: '6px' }}>الترويج لخدمات مشابهة أو منافسة بدون تصريح من "هاللو".</li>
    <li style={{ marginBottom: '6px' }}>رفع أو مشاركة فيروسات أو برمجيات خبيثة.</li>
</ul>

                <h1 className="font-bold text-md mb-3 mt-3">حقوق الطبع والنشر</h1>
                <p className='mb-[6px] text-sm'>  . منصة "هاللو" تحترم حقوق الملكية الفكرية للآخرين
                </p>
                
                <p className='mb-3 text-sm'> . أي محتوى غير مرخص قد يُحذف، وقد تُنهي المنصة حسابات الأعضاء الذين يخالفون هذه السياسات بانتظام

                </p>
                <h1 className="font-bold text-md mb-3">سياسة الدعم</h1>
                <p className='mt-3 mb-3 text-sm'> . أي محتوى غير مرخص قد يُحذف، وقد تُنهي المنصة حسابات الأعضاء الذين يخالفون هذه السياسات بانتظام
                </p>

                <ul className='mr-10 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>تطوير وتحسين تجربة الاستخدام.</li>
    <li style={{ marginBottom: '6px' }}>توفير الدعم الفني خلال الحصص.</li>
    <li style={{ marginBottom: '6px' }}>توجيه المستخدمين حول كيفية استخدام النظام ودفع الرسوم وتحميل الشهادات.</li>
</ul>

                <h1 className="font-bold text-md mt-3 mb-3"> : التعويض</h1>

                <p className='mb-3 text-sm'>  أنت تقر بتعويض منصة "هاللو" عن أي دعاوى أو التزامات تنشأ نتيجة لاستخدامك للمنصة أو انتهاكك لأي قوانين أو حقوق</p>

                <h1 className="font-bold text-md mb-3">إخلاء المسؤولية من الضمان:</h1>
                <p className='mb-3 text-sm'>منصة "هاللو" تقدم خدماتها كما هي، ولا تضمن نتائج محددة من استخدام النظام. استخدامك للخدمة هو تحت مسؤوليتك الشخصية.</p>

                <h1 className="font-bold text-md mb-3">سياسة الخصوصية</h1>

                <p className="mb-3 text-sm">تمثل سياسة الخصوصية الحالية ("السياسة") الخطوط الإرشادية الداخلية الخاصة بإدارة وموظفي ومتعاقدي منصة "هاللو" (www.hallo.sa)، والتي تتعلق بالتعامل مع معلومات المستخدمين، بما يشمل المعلومات الشخصية وغير الشخصية. عندما يقوم المستخدمون بتقديم ومشاركة معلومات معينة، يُعد بعضها شخصياً وبعضها الآخر معلومة عامة (تعرف مجتمعة باسم "معلومات المستخدِم").<br/><br/>تهدف هذه السياسة إلى توضيح كيفية تعاملنا مع المعلومات الشخصية وغير الشخصية التي قد نجمعها من خلال تفاعلك مع منصتنا. نحن نحترم خصوصيتك ونسعى إلى توفير حماية لبياناتك بناءً على القوانين والأنظمة المعمول بها.<br/><br/>جمع المعلومات الشخصية وغير الشخصية</p>
                <h1 className="font-bold text-md mb-3"> : المعلومات التعريفية غير الشخصية</h1>

                <p className="mb-3 text-sm">تجمع منصة "هاللو" معلومات تعريفية غير شخصية مثل نوع المتصفح، الموقع الذي زرت منه المنصة، وقت وتاريخ الزيارة، وذلك لتحسين تجربتك وفهم سلوك المستخدمين. قد نقوم بنشر معلومات إحصائية حول سلوك المستخدمين مثل الدروس الأكثر حجزًا، ولكن لن نعرض أي معلومات شخصية.</p>
                <h1 className="font-bold text-md mb-3"> : المعلومات الشخصية التعريفية
                </h1>

                <p className="mb-3 text-sm">نحن ملتزمون بحماية معلوماتك الشخصية، والتي قد تشمل اسمك، عنوان بريدك الإلكتروني، رقم هاتفك، تفاصيل الدفع، وغيرها من المعلومات الضرورية لتقديم خدمات الحجز والدروس عبر منصتنا.<br/><br/> : نحن نجمع هذه المعلومات للأغراض التالية</p>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>تمكينك من الحجز مع المدرسين وتنفيذ المدفوعات.</li>
    <li style={{ marginBottom: '6px' }}>تخصيص المحتوى والخدمات بما يتناسب مع احتياجاتك.</li>
    <li style={{ marginBottom: '6px' }}>إرسال إشعارات وإعلانات تتعلق بخدمات المنصة.</li>
    <li style={{ marginBottom: '6px' }}>استطلاع رأيك حول تجربتك في المنصة وتحسين خدماتنا.</li>
</ul>


                <h1 className="font-bold text-md mb-3">مشاركة المعلومات مع الغير</h1>
                <p className=" text-sm">قد نشارك بعض المعلومات مع الأطراف الثالثة المرتبطة بنا، بما في ذلك المدرسين الذين يتم حجز الدروس معهم، أو مقدمي الخدمات الآخرين مثل بوابات الدفع الإلكترونية. نحن نتعاون أيضًا مع السلطات القانونية في حال كانت هناك حاجة قانونية لمشاركة معلومات المستخدم في سياق التحقيقات أو الدعاوى القضائية</p><br/><br/>
                <h1 className="font-bold text-md mb-3 mt-[-32px]">أمن المعلومات</h1>

                <p className="mb-5 text-sm">نحن نلتزم باتباع المعايير المقبولة عمومًا لحماية معلومات المستخدمين. ورغم أن أي وسيلة نقل عبر الإنترنت أو تخزين إلكتروني لا يمكن أن تكون آمنة تمامًا، فإننا نسعى لتطبيق أفضل الوسائل المتاحة لضمان حماية بياناتك الشخصية.</p>
                <h1 className="font-bold text-md mb-3"> : ملفات الكوكيز</h1>
                
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
                <li style={{ marginBottom: '6px' }}>منصة هاللو تستخدم بيانات ملفات الكوكيز لمعرفة وتتبع المستخدمين وطريقة استخدامهم للمنصة، بما في ذلك نوع المتصفح ونظام التشغيل ومزود الخدمة وتفضيلات الوصول للموقع. هذه المعلومات لا تتضمن تفاصيل شخصية، وتساعد في تحسين تجربة المستخدم. إذا كنت لا ترغب في تخزين ملفات الكوكيز، يمكنك تعطيلها من إعدادات المتصفح.</li>
                <li style={{ marginBottom: '6px' }}>ملاحظة: بعض ميزات منصة هاللو قد لا تعمل بشكل صحيح بدون الكوكيز.</li>
                </ul>


                <h1 className="font-bold text-md mb-3"> : عنوان الإنترنت IP</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>تُستخدم عناوين الإنترنت (IP) لتتبع وتحليل كيفية استخدام الزوار للمنصة، وذلك لتحسين تجربة المستخدم وتطوير الخدمات المقدمة. إذا كنت لا ترغب في السماح بتتبع هذه البيانات، يمكنك ضبط إعدادات المتصفح وفقًا لذلك.</li>
</ul>

                <h1 className="font-bold text-md mb-3"> : الأمان
                </h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>جميع المعلومات الشخصية وغير الشخصية التي نحصل عليها محفوظة ضد الوصول غير المصرح له.</li>
    <li style={{ marginBottom: '6px' }}>يتم الكشف عن هذه المعلومات فقط عندما يكون ذلك مطلوبًا بموجب القانون أو لحماية حقوق المستخدمين والمنصة.</li>
    <li style={{ marginBottom: '6px' }}>المعلومات المالية، مثل تفاصيل الدفع، تُخزن بشكل آمن ومشفّر.</li>
</ul>

                <h1 className="font-bold text-md mb-3"> : المعلومات المالية</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>عند إجراء أي عمليات دفع، يجب إدخال المعلومات الصحيحة بما في ذلك الاسم القانوني.</li>
    <li style={{ marginBottom: '6px' }}>جميع المدفوعات والمعلومات المالية تحفظ بسرية تامة ولا يمكن الوصول إليها إلا لموظفي منصة هاللو أو الوسيط المالي المستخدم. لن يتم الإفصاح عن هذه المعلومات إلا عند الحاجة القانونية.</li>
</ul>

                <h1 className="font-bold text-md mb-3"> : الروابط لمواقع أخرى</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>منصة هاللو لا تتحمل المسؤولية عن المحتوى أو الخدمات في مواقع الطرف الثالث. ننصح المستخدمين بقراءة سياسات الخصوصية والشروط الخاصة بأي موقع خارجي قبل استخدامه.</li>
</ul>

                <h1 className="font-bold text-md mb-5"> : الدعم والمساندة الأكاديمية</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
    <li style={{ marginBottom: '6px' }}>تقدم منصة هاللو دعمًا فنيًا وتعليميًا للطلاب والمدرسين أثناء تنفيذ الدروس عبر القاعات الافتراضية. يوجد مدير للبرنامج يساعد في التنظيم وحل المشكلات التقنية للطلاب.</li>
</ul>

                <h1 className="font-bold text-md mb-3">: الدعم والمساندة الأكاديمية</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
                    <li style={{ marginBottom: '6px' }}>تتبنى منصة هاللو سياسات النزاهة الأكاديمية لضمان التزام الطلاب بحضور الحصص بشكل منتظم والتفاعل مع المدرسين. </li>
                </ul>
                <h1 className="font-bold text-md mb-5"> : آلية التحقق من هوية المستخدم</h1>
                <ul className='mr-10 mb-3 text-sm' style={{ listStyleType: 'disc', paddingLeft: '20px',  direction: 'rtl', textAlign: 'right' }}>
                    <li style={{ marginBottom: '6px' }}>يتحقق نظام "هاللو" من هوية المستخدم من خلال خطوات مثل إرسال رسالة تأكيد عبر البريد الإلكتروني أو الهاتف المحمول. </li>
                    <li style={{ marginBottom: '6px' }}>يمكن للمستخدمين تعديل بياناتهم فقط بعد التحقق الأمني اللازم.</li>
                </ul>

                

                

               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popop;
