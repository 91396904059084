// import React, { useState } from 'react';
// import Popop from '../Components/Popop';


// const Footer = () => {
//   const [isPopopOpen, setIsPopopOpen] = useState(false);

//   // Function to open and close the Popop component
//   const handlePopopClick = () => {
//     setIsPopopOpen(!isPopopOpen);
//   };
//   return (
//     <>
//     {isPopopOpen && <Popop />}
//       <div className="relative lg:mb-[-25px] mb-[-9px]">
//         <img
//           src="vector 2.png"
//           alt="Background"
//           className="w-full lg:h-[150px] h-[60px]"
//         />

//         {/* Logo and Social Icons */}
//         <div className="absolute lg:top-9 md:top-3 top-4 md:left-[18%] left-[18%] transform -translate-x-1/2">
//           <img
//             src="Frame 1171275990.png"
//             alt="Frame"
//             className="lg:w-[162px] md:w-[120px] w-[67px] lg:h-[61px] h-6 md:h-9"
//           />
//           {/* <div className="lg:mt-10 mt-3 flex space-x-3 justify-center">
//             <img
//               src="Facebook.png"
//               alt="Facebook"
//               className="lg:w-7 lg:h-7 w-4 h-4"
//             />
//             <img src="Group.png" alt="Group" className="lg:w-7 lg:h-7 w-4 h-4" />
//             <img
//               src="Frame 1171275977.png"
//               alt="Frames"
//               className="lg:w-7 lg:h-7 w-4 h-4"
//             />
//             <img
//               src="LinkedIn.png"
//               alt="LinkedIn"
//               className="lg:w-7 lg:h-7 w-4 h-4"
//             />
//             <img src="TikTok.png" alt="TikTok" className="lg:w-7 lg:h-7 w-4 h-4" />
//           </div> */}
//         </div>

//         {/* FAQ and Terms */}
//         <div className="absolute lg:top-[75px] md:top-6 top-7 lg:right-12 md:right-36 right-[29%] transform translate-x-1/2 lg:translate-x-0">
//           <p className="text-xs md:text-sm text-center">
//             <span> الباقات </span>
//             <span className="lg:mx-6 mx-3"></span>
//             <span  onClick={handlePopopClick} className="cursor-pointer">الشروط والأحكام</span>
//           </p>
//         </div>
//       </div>

//       {/* Footer Content */}
//       <div className="relative lg:top-1 top-1">
//         <div className="bg-black w-full text-center flex flex-col items-center justify-center relative h-10">
//           <img
//             src="Vector 801.png"
//             alt="Vector 801"
//             className="h-full object-contain"
//           />
//           <p className="absolute text-white text-sm">
//             جميع الحقوق محفوظة © منصة هالو
//           </p>
//         </div>
//       </div>

//       {/* Render Popop if it's open */}
      
//     </>
//   );
// };

// export default Footer;



import React, { useState } from 'react';
import Popop from '../Components/Popop';

const Footer = () => {
  const [isPopopOpen, setIsPopopOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // Added state to manage active section

  // Function to open and close the Popop component
  const handlePopopClick = () => {
    setIsPopopOpen(!isPopopOpen);
  };

  // Function to scroll to the "الباقات" section
  const scrollToSection = (sectionId) => {
    setActiveSection(sectionId); // Set active section for styling
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {isPopopOpen && <Popop />}
      <div className="relative lg:mb-[-25px] mb-[-9px]">
        <img
          src="vector 2.png"
          alt="Background"
          className="w-full lg:h-[140px] h-[60px]"
        />

        {/* Logo and Social Icons */}
        <div className="absolute lg:top-9 md:top-3 top-4 md:left-[18%] left-[18%] transform -translate-x-1/2">
          <img
            src="Group212.svg"
            alt="Frame"
            className="lg:w-[160px] md:w-[120px] w-[67px] lg:h-[50px] h-6 md:h-9"
          />
        </div>

        {/* FAQ and Terms */}
        <div className="absolute lg:top-[65px] md:top-6 top-7 lg:right-12 md:right-36 right-[29%] transform translate-x-1/2 lg:translate-x-0">
          <p className="text-xs md:text-sm text-center">
            <span
              className={`cursor-pointer ${activeSection === 'packages' ? '' : ''}`}
              onClick={() => scrollToSection('packages')}
            >  الباقات </span>
            <span className="lg:mx-6 mx-3"></span>
            <span onClick={handlePopopClick} className="cursor-pointer">الشروط والأحكام</span>
          </p>
        </div>
      </div>

      {/* Footer Content */}
      <div className="relative lg:top-1 top-1">
        <div className="bg-black w-full text-center flex flex-col items-center justify-center relative h-10">
          <img
            src="Vector 801.png"
            alt="Vector 801"
            className="h-full object-contain"
          />
          <p className="absolute text-white text-sm">
            جميع الحقوق محفوظة © منصة هالو
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
